@import "/src/commonStyles/mixins.scss";

.QM-rwiApplication {
  &_container {
    display: flex;
    padding: 14px 0;
    align-items: flex-start;
    .QM-tile {
			@media screen and (max-width: 1142px) {
				max-width: 50%
			}
			&:last-child {
		width: 600px;
		min-width: 600px;
		max-width: 600px;
		margin-left: 14px;
		@media screen and (max-width: 1142px) {
			width: auto;
			min-width: auto;
		}
	}}

		.QM-docWidget{
			&_header {
				padding: 15px 24px;
				box-shadow: inset 0px -1px 0px #EBEFF2;
				display: flex;
				justify-content: space-between;
				h4 {
					@include rubik;
					@include text-regular;
					color: var(--col-label-text);
				}
				.QM-docWidget_warning {
					@include rubik;
					@include text-regular;
					color: var(--col-status-warning);
				}
			}
		}
		&_body {
			padding: 24px;
		}
  }
}

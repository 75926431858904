@import "/src/commonStyles/mixins.scss";

.tippy-marker {
  @include rubik;
  color: var(--col-active-text);
  line-height: 20px;

  &__header,
  &__body {
    padding: 4px 12px 0 12px;
  }
  &__header {
    font-size: 14px;
    font-weight: 500;
  }

  &__body {
    margin-top: 6px;
    font-size: 10px;
    font-weight: 300;
  }
}

.assign-lawyer-modal {
  width: 750px;
  position: relative;

	&__title {
		word-break: break-word;
	}

  &__footer {
    display: flex;
    justify-content: right;
    .QM-btn {
      width: 100px;
      color: var(--col-btn);
      transition: transform 0s;
      padding: 10px 15px;
      border: 1px solid var(--col-btn);
      background-color: var(--col-white);

      &:hover {
        background-color: var(--col-btn);
        color: var(--col-white);
      }

      &:active {
        transform: scale(0.95);
      }
    }
  }

  &__body {
    height: 400px;
		overflow-y: auto;
		margin: 8px 0;
  }

  &__search {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    form {
      flex-basis: 900px;
    }

    .QM-btn {
      margin-left: 24px;
    }
  }

  &__btn-inactive {
    background-color: var(--col-label-text);
    border: 1px solid var(--col-label-text);
  }

  &__btn-active {
    background-color: var(--col-btn);
    border: 1px solid var(--col-btn);
  }

  &__empty-list {
    margin-top: 24px;
    font-size: 14px;
    color: var(--col-label-text);
  }

  &__pending-list {
    margin-top: 24px;
  }

  &__pending-list-item {
    position: relative;
    padding: 12px 0;
    display: grid;
    grid-template-columns: 1fr 72px 143px 120px;
    grid-column-gap: 8px;
		&__userInfo {
			display: flex;

			&__avatarWrapper {
				position: relative;
		
			}
			& &__delete-btn {
				border-radius: 50%;
				background-color: var(--col-table-row-hover);
				padding: 4px;
				border: 1px solid var(--col-input-border);
				position: absolute;
				right: 0;
				top: 24px;
				& .icon-trash {
					&::before {
						font-size: 12px;
					}
				}
				&:hover {
					color: var(--col-status-error);
					background-color: var(--col-status-error-light2);
					border-color: var(--col-status-error);
				}
			}
		}

    &__name {
      font-size: 14px;
      display: inline-block;
      padding-top: 5px;
    }

    &__email {
      font-size: 12px;
      color: var(--col-label-text);
    }

    &__status {
      display: inline-block;
      padding-top: 13px;
      font-size: 12px;
      color: var(--col-label-text);
    }

    & > .QM-btn {
			&__transparent {
				font-size: 14px;
				font-weight: 400;
				color: var(--col-btn);
				padding: 10px 0;
				&:active {
					transform: scale(0.95);
				}
			}
      
      &__resend-btn {
        width: 100%;
        border: 1px solid var(--col-btn);

        &:hover {
          background-color: var(--col-btn-p-hover);
          color: var(--col-white);
        }
      }

      &__manage-access-btn {
        width: 120px;
        border: 1px solid transparent;

        &:hover {
          color: var(--col-btn-p-hover);
          border: 1px solid var(--col-btn-p-hover);
          background-color: var(--col-btn-s-hover);
        }
      }
    }
  }

	.QM-toast {
		z-index: 20;
	}
}

.assign-lawyer-manage-access {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  min-width: 490px;
  padding: 24px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  background-color: var(--col-white);

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 10;
  }

  &__header {
    display: flex;
    justify-content: space-between;
		align-items: flex-start;

		h2 {
			word-break: break-word;
		}
  }

  &__footer {
    display: flex;
		flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 24px;
  }

  &__title {
    font-size: 18px;
    color: var(--col-active-text);
    position: relative;

		&__user {
			margin-bottom: 4px;
		}
  }

	&__userInfo {
		display: flex;
	}
  &__list {
    margin-top: 24px;
    border-bottom: 1px solid var(--col-inactive-text);
  }

  &__item {
    position: relative;
    display: flex;
    margin-bottom: 24px;

    .icon-folder-open {
      margin: -2px 12px 0 12px;
      color: var(--col-label-text);
    }

    &_name {
      color: var(--col-active-text);
      font-size: 14px;
      margin-top: 3px;
    }
  }

  &__item-policies {
    margin-left: 32px;
  }

  &__invite-btn {
    padding: 20px 16px;
    height: 40px;
  }

	&__dropdown {
		display: flex;
		align-items: stretch;
		cursor: pointer;

		.icon-triangle-down,
    .icon-triangle-up {
      cursor: pointer;
      color: var(--col-btn);
      margin-top: -5px;

      &:hover {
        color: var(--col-btn-p-hover);
      }
    }

		&--disabled {
			cursor: not-allowed;

			.icon-triangle-down,
    	.icon-triangle-up {
				color: var(--col-btn-p-disabled);
				cursor: not-allowed;
				&:hover {
					color: var(--col-btn-p-disabled);
				}
			}
		}
	}
  &__level {
    font-size: 14px;
    margin-top: 3px;
    color: var(--col-btn);
    margin-left: 24px;
		&:hover {
			color: var(--col-btn-p-hover);
		}
		&--disabled {
			color: var(--col-btn-p-disabled);
			&:hover {
        color: var(--col-btn-p-disabled);
      }
		}
  }

  .QM-userBlock_dropdown {
    position: absolute;
    color: var(--col-active-text);
    font-size: 14px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    left: 180px;
    top: 80%;
    bottom: 0;
    right: 0;
    height: 67px;
  }
}

.assign-lawyer-revoke-access {
	&__header {
		align-items: center;
	}
}
